import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import theme from '../../theme';


const Meta = ({ title, description, lang }) => {
	const meta = {
		title: title ? `${title} | ${theme.title}` : theme.title,
		description: description || theme.description,
	};

	return (
		<Helmet
			htmlAttributes={{ lang }}
		>
			<meta name="description" content={meta.description} />

			<link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
			<link rel="manifest" href="/images/favicons/site.webmanifest" />
			<link rel="shortcut icon" href="/images/favicons/favicon.ico" />
			<meta name="msapplication-TileColor" content="#abdce7" />
			<meta name="msapplication-config" content="/images/favicons/browserconfig.xml" />
			<meta name="theme-color" content="#abdce7" />

			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={meta.title} />
			<meta name="twitter:description" content={meta.description} />
			<meta name="twitter:image" content={`${theme.siteUrl}images/share/twitter-500x500.png`} />

			<meta property="og:title" content={meta.title} />
			<meta property="og:description" content={meta.description} />
			<meta property="og:image" content={`${theme.siteUrl}images/share/facebook-1200x630.png`} />
			<meta property="og:site_name" content={theme.title} />
			<meta property="og:url" content={theme.siteUrl} />

			<title>{meta.title}</title>
		</Helmet>
	);
};

Meta.propTypes = {
	lang: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

Meta.defaultProps = {
	lang: theme.lang,
};


export default Meta;
