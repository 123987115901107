import React, { Fragment } from 'react';
// import * as ReactRevealFade from 'react-reveal/Fade';


const Fade = ({ children }) => (
	<Fragment>
		{children}
	</Fragment>
);


export default Fade;
