import { css } from '@emotion/core';
import React from 'react';

const styles = css`
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	border: 0;
`;

const SrOnlyTitle = ({ children }) => (
	<h1 css={styles}>
		{children}
	</h1>
);

export default SrOnlyTitle;
